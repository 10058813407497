import React from 'react';
import DonutChart from 'react-svg-donut-chart';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const COMPONENT_RENDER_MAP = {
  text: ({marks, value}) => {
    return marks.length ? (
      <span className={marks.map(mark => mark.type).join(' ')}>{value}</span>
    ) : (
      value
    );
  },
  paragraph: (item, classes) => (
    <Typography component="p" key={`p-${Math.random()}`}>
      {item.content.map(item => renderItem(item, classes))}
    </Typography>
  ),
  'heading-1': (item, classes) => (
    <h1>{item.content.map(item => renderItem(item, classes))}</h1>
  ),
  'heading-2': (item, classes) => (
    <h2>{item.content.map(item => renderItem(item, classes))}</h2>
  ),
  'heading-3': (item, classes) => (
    <h3>{item.content.map(item => renderItem(item, classes))}</h3>
  ),
  'heading-4': (item, classes) => (
    <h4>{item.content.map(item => renderItem(item, classes))}</h4>
  ),
  'image/jpeg': (item, classes) => (
    <CardMedia
      key={item.entry.file.url}
      image={item.entry.file.url}
      title={item.entry.description}
      component="img"
      className={classes.media}
    />
  ),
  'image/png': (item, classes) => (
    <CardMedia
      key={item.entry.file.url}
      image={item.entry.file.url}
      title={item.entry.description}
      component="img"
      className={classes.media}
    />
  ),
  Asset: (item, classes) =>
    renderItem(
      {
        nodeType: item.entry.fields.file.contentType,
        entry: item.entry.fields,
      },
      classes,
    ),
  // embedded entries
  'embedded-entry-block': (item, classes) =>
    renderItem(
      {
        nodeType: item.data.target.sys.contentType.sys.id,
        entry: item.data.target,
      },
      classes,
    ),
  'embedded-asset-block': (item, classes) =>
    renderItem(
      {
        nodeType: item.data.target.sys.type,
        entry: item.data.target,
      },
      classes,
    ),
  'embedded-entry-inline': ({data}) => (
    <span className="highlight">{data.target.fields.title}</span>
  ),
  chart: ({entry}) => <DonutChart data={entry.fields.data} />,
  video: ({entry}) => (
    <iframe
      title={entry.fields.title}
      width="560"
      height="315"
      src={entry.fields.url}
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    />
  ),
};

const logItem = item => {
  console.log('\n\n--Rendering item---------------');
  console.log(
    `%c item.nodeType %c ${item.nodeType}`,
    'background: #0074D9; color: #fff; border-radius: 3px',
    'background: transparent; color: #444;',
  );
  console.log(
    '%c item.content  ',
    'background: #7FDBFF; color: #4a4a4a; border-radius: 3px',
    item.content,
  );

  console.log(
    '%c item.value  ',
    'background: #FFDC00; color: #4a4a4a; border-radius: 3px',
    item.value,
  );

  console.log(
    '%c item.marks  ',
    'background: #FF851B; color: #4a4a4a; border-radius: 3px',
    item.marks,
  );

  console.log(
    '%c item (complete object)  ',
    'background: #FF4136; color: #fff; border-radius: 3px',
    item,
  );
};

export const renderItem = (item, classes) => {
  try {
    return COMPONENT_RENDER_MAP[item.nodeType](item, classes);
  } catch (error) {
    logItem(item);
    console.log('Unknown content type');
    console.log(error);
  }
};
