import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { useAuth0 } from "../../react-auth0-spa";
import { API } from "aws-amplify";
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  }
}));
const SportManagement = () => {
  const classes = useStyles();
  const { loading, user, getTokenSilently } = useAuth0();

  const [values, setValues] = useState({
    sport: "",
    formDisabled: false,
    tainted: false
  });
  const [sports, setSports] = useState([]);
  const [localSports, setLocalSports] = useState([]);
  const [sportsLoading, setSportsLoading] = useState(false);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value, tainted: true });
  };

  useEffect(() => {
    async function getSports() {
      const token = await getTokenSilently();
      let apiName = "youcapper";
      let path = "/sports";
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`
        }
      };
      setSportsLoading(true);
      try {
        let sports = await API.get(apiName, path, myInit);
        setSportsLoading(false);
        setSports(sports);
      } catch (err) {
        console.log(err);
      }
    }
    getSports();
    console.log("Getting Sports");
  }, [getTokenSilently, localSports]);

  const handleAddSport = () => {
    setValues({ ...values, formDisabled: !values.formDisabled });
    async function postData() {
      const token = await getTokenSilently();
      let apiName = "youcapper";
      let path = "/sports";
      let myInit = {
        body: {
          league: values.league,
          sport: values.sport
        },
        headers: {
          authorization: `Bearer ${token}`
        }
      };
      if (values.sport && values.sport !== "") {
        setValues({ ...values, loading: true, formDisabled: true });
        await API.post(apiName, path, myInit);
        setValues({ ...values, loading: false, formDisabled: false });
        setLocalSports([...sports, { name: values.sport }]);
      }
    }
    postData();
  };

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <List>
        {(sportsLoading && <CircularProgress />) ||
          sports.map(l => {
            return (
              <ListItem key={l.name}>
                <ListItemText primary={l.name} />
              </ListItem>
            );
          })}
      </List>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <TextField
            required
            id="sport-input"
            label="Sport"
            variant="filled"
            onChange={handleChange("sport")}
          />
          <Button
            variant="contained"
            onClick={() => handleAddSport()}
            disabled={values.formDisabled}
          >
            Add Sport
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default SportManagement;
