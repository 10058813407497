import * as ACTION from "./actions";
var moment = require('moment');

export const initialState = {
  league: "NBA",
  inputText: "",
  queryId: "",
  pickDate: moment().format("YYYY-MM-DD")
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.SET_LEAGUE:
      return {
        ...state,
        league: action.payload
      };
    case ACTION.UPDATE_QUERY_ID:
      return {
        ...state,
        queryId: action.payload
      };
    case ACTION.UPDATE_PICK_DATE:
      return {
        ...state,
        pickDate: action.payload
      };
    default:
      return initialState;
  }
}
