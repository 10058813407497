import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Link } from "react-router-dom";

import { useAuth0 } from "../../react-auth0-spa";

export default function LoginGroup(props) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <>
      {!isAuthenticated && (
        <ListItem
          button
          color="secondary"
          component={Link}
          onClick={() => loginWithRedirect({})}
          to="/"
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      )}

      {isAuthenticated && (
        <ListItem
          button
          color="secondary"
          component={Link}
          onClick={() => logout()}
          to="/"
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      )}
    </>
  );
}
