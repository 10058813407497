import React from 'react';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import {useAuth0} from './react-auth0-spa';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from './theme';
import './App.css';
import Routes from './Routes';
import Menu from './components/navigation/Menu';
import {Router, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import history from './utils/history';

history.listen(location => {
  ReactGA.set({page: location.pathname}); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  const {loading, user} = useAuth0();

  if (user) {
    ReactGA.set({userId: user.sub, email: user.email});
    Sentry.configureScope(function(scope) {
      scope.setUser({email: user.email, id: user.sub});
    });
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={1}>
              <Grid item xs={12} container>
                <Menu />
              </Grid>
              <Grid item xs={12} container>
                <Routes />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </QueryParamProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
