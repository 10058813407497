import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import MUIDataTable from "mui-datatables";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { KeyboardDatePicker } from "@material-ui/pickers";

import { API } from "aws-amplify";
import { useAuth0 } from "../react-auth0-spa";

import { StateContext, DispatchContext } from "./Context";
import { UPDATE_QUERY_ID, UPDATE_PICK_DATE } from "../actions";

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  note: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function Picks() {
  const classes = useStyles();
  const state = useContext(StateContext);
  const [pickData, setPickData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getTokenSilently, loading, claims } = useAuth0();
  const dispatch = useContext(DispatchContext);
  const updateQueryId = newValue => {
    console.log(`Button Click: ${newValue}`);
    dispatch({ type: UPDATE_QUERY_ID, payload: newValue });
  };

  const updateDate = newDate => {
    dispatch({ type: UPDATE_PICK_DATE, payload: newDate });
  };

  useEffect(() => {
    async function getData(league) {
      const token = await getTokenSilently();
      let apiName = "youcapper";
      let path = "/picks";
      let myInit = {
        queryStringParameters: {
          league: league,
          admin: false,
          date: (state.pickDate && state.pickDate.format("YYYY-MM-DD")) || ""
        },
        headers: {
          authorization: `Bearer ${token}`
        }
      };
      if (
        !loading &&
        typeof claims !== "undefined" &&
        claims["https://youcapperpicks.com/permissions"] &&
        claims["https://youcapperpicks.com/permissions"].includes("app:admin")
      ) {
        myInit.queryStringParameters.admin = true;
      }
      setIsLoading(true);
      try {
        let picks = await API.get(apiName, path, myInit);
        setIsLoading(false);
        setPickData(picks);
      } catch (err) {
        console.log(err);
      }
    }
    getData(state.league);
  }, [state.league, state.pickDate, getTokenSilently, claims, loading]);
  const columns = [
    {
      name: "game_date",
      label: "Game Date",
      options: { filter: true, sort: true }
    },
    {
      name: "team",
      label: "Team",
      options: { filter: true, sort: true }
    },
    {
      name: "opponent",
      label: "Opponent",
      options: { filter: true, sort: true }
    },
    {
      name: "total",
      label: "Total",
      options: { filter: false, sort: false, display: "false" }
    },
    {
      name: "line",
      label: "Line",
      options: { filter: false, sort: false, display: "false" }
    },
    {
      name: "description",
      label: "Pick",
      options: { filter: false, sort: false }
    },
    {
      name: "pick_date",
      label: "Pick Date",
      options: { filter: false, sort: false, display: "false" }
    },
    {
      name: "query_id",
      label: "Query",
      options: { filter: true, sort: true }
    }
  ];

  const options = {
    search: false,
    print: false,
    download: false,
    selectableRows: "none",
    responsive: "scrollMaxHeight"
  };

  return (
    <>
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      <KeyboardDatePicker
        clearable
        value={state.pickDate}
        placeholder="today"
        onChange={date => updateDate(date)}
        format="YYYY-MM-DD"
        maxDate={new Date()}
      />
      <MUIDataTable
        title={`${state.league} Picks`}
        data={pickData.filter(q => q.query_active)}
        columns={columns}
        options={options}
        className={classes.table}
      />
      <Card className={classes.note}>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            System picks are not built to win every game, but to identify an
            edge. For this reason, system picks are not recommended to follow
            blindly.
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
