import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Queries from "./queries/Queries";
import Leagues from "./Leagues";

export default function WrappedQueries() {
  return (
    <>
      <Container fixed>
        <Grid item>
          <Queries />
        </Grid>
      </Container>
      <Leagues />
    </>
  );
}
