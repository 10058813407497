import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import {BrowserRouter as Router} from 'react-router-dom';
import {Auth0Provider} from './react-auth0-spa';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import config from './config';
import auth0Config from './auth_config.json';
import history from './utils/history';
//import * as V from 'victory';
import * as serviceWorker from './serviceWorker';
Sentry.init({
  dsn: 'https://2c5dad05f19142478aa9f459d3378c22@sentry.io/2840598',
});

if (process.env.REACT_APP_GTAG_ADS) {
  ReactGA.initialize(
    [
      {trackingId: process.env.REACT_APP_GTAG},
      {trackingId: process.env.REACT_APP_GTAG_ADS, gaOptions: {name: 'ads'}},
    ],
    {debug: false},
  );
} else {
  ReactGA.initialize(
    [{trackingId: process.env.REACT_APP_GTAG, gaOptions: {name: 'analytics'}}],
    {debug: true},
  );
}

Amplify.configure({
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'youcapper',
        endpoint: `${process.env.REACT_APP_PICKS_API_BASE}`,
        region: config.apiGateway.REGION,
      },
      {
        name: 'youcapper-local',
        endpoint: 'http://localhost:8000',
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={auth0Config.domain}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}>
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
