export default {
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'dev.youcapperpicks.com',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: `${process.env.REACT_APP_PICKS_API_BASE}`,
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_hkGekPfSU',
    APP_CLIENT_ID: '32i2l29jlq91f9sf6iu0skb8ni',
    IDENTITY_POOL_ID: 'us-west-2:1de764ff-707f-4874-aa6c-1977ef656935',
  },
  oauth: {
    domain: 'dev-youcapper.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};
