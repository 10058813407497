import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import { useAuth0 } from "../../react-auth0-spa";
import { API } from "aws-amplify";
import { DispatchContext, StateContext } from "../Context";

const DatabaseAdmin = () => {
  const { loading, user, claims, getTokenSilently } = useAuth0();
  const state = useContext(StateContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function rebuildTables() {
    const token = await getTokenSilently();
    let apiName = "youcapper";
    let path = "/admin/functions/rebuild";
    let myInit = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };
    setIsLoading(true);
    let data = await API.get(apiName, path, myInit);
    setIsLoading(false);
    setData(data);
  }

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Button variant="contained" onClick={() => rebuildTables()}>
        Rebuild Tables
      </Button>
    </Container>
  );
};

export default DatabaseAdmin;
