// src/components/PrivateRoute.js

import React, { useEffect, useReducer } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import { StateContext, DispatchContext } from "../Context";
import { reducer } from "../../reducers";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      console.log(`Authd: ${isAuthenticated}`);
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const [state, dispatch] = useReducer(reducer, { league: "NBA" });

  const render = props =>
    isAuthenticated === true ? (
      <DispatchContext.Provider value={dispatch}>
        <StateContext.Provider value={state}>
          <Component {...props} />
        </StateContext.Provider>
      </DispatchContext.Provider>
    ) : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
