import React from 'react';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import NavMenuItem from './NavMenuItem';

import {toProperCase} from '../../utils';

export default function NavGroup(props) {
  return (
    <List
      key={`${props.name}-menu-list`}
      subheader={
        <ListSubheader component="div" id={`${props.name}-menu-list-subheader`}>
          {toProperCase(props.name)}
        </ListSubheader>
      }>
      {props.menuGroup.map(item => (
        <NavMenuItem
          key={`parent-${props.name}-menu-${item.name}`}
          name={item.name}
          icon={item.icon}
          route={item.route}
          menuKey={`${props.name}-menu-${item.name}`}
        />
      ))}
    </List>
  );
}
