import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {useAuth0} from '../../react-auth0-spa';
import {API} from 'aws-amplify';
const LeagueManagement = () => {
  const {loading, user, getTokenSilently} = useAuth0();
  const [sports, setSports] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [sportsLoading, setSportsLoading] = useState(false);
  const [leaguesLoading, setLeaguesLoading] = useState(false);
  const [localLeagues, setLocalLeagues] = useState([]);

  const [values, setValues] = React.useState({
    league: '',
    sport: '',
    loading: false,
  });

  const handleChange = prop => event => {
    setValues({...values, [prop]: event.target.value, tainted: true});
  };

  useEffect(() => {
    async function getLeagues() {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = '/leagues';
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      setLeaguesLoading(true);
      try {
        let leagues = await API.get(apiName, path, myInit);
        setLeagues(leagues);
        setLeaguesLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    async function getSports() {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = '/sports';
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      setSportsLoading(true);
      try {
        let sports = await API.get(apiName, path, myInit);
        setSports(sports);
        setSportsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    getLeagues();
    getSports();
  }, [getTokenSilently, localLeagues]);

  const handleAddLeague = () => {
    setValues({...values, formDisabled: !values.formDisabled});
    async function postData() {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = '/leagues';
      let myInit = {
        body: {
          league: values.league,
          sport: values.sport,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      if (values.league && values.league !== '') {
        setValues({...values, loading: true, formDisabled: true});
        await API.post(apiName, path, myInit);
        setValues({...values, loading: false, formDisabled: false});
        setLocalLeagues([...leagues, {name: values.league}]);
      }
    }
    postData();
  };

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <List>
        {leagues.map(l => {
          return (
            <ListItem key={l.name}>
              <ListItemText primary={l.name} />
            </ListItem>
          );
        })}
      </List>
      <div>
        <TextField
          required
          id="league-input"
          label="League"
          variant="filled"
          value={values.league}
          onChange={handleChange('league')}
        />
        <Select
          required
          id="sport-input"
          label="Sport"
          variant="filled"
          disabled={values.formDisabled}
          value={values.sport}
          onChange={handleChange('sport')}>
          {sports.map(item => {
            return (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>

        <Button
          variant="contained"
          onClick={() => handleAddLeague()}
          disabled={values.formDisabled}>
          Add League
        </Button>
      </div>
    </Container>
  );
};

export default LeagueManagement;
