import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import { useAuth0 } from "../../react-auth0-spa";
import { API } from "aws-amplify";
import { DispatchContext, StateContext } from "../Context";

const UserInfo = () => {
  const { loading, user, claims, getTokenSilently } = useAuth0();
  const state = useContext(StateContext);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      const token = await getTokenSilently();
      let apiName = "youcapper";
      let path = "/test/user";
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`
        }
      };
      setIsLoading(true);
      let user_data = await API.get(apiName, path, myInit);
      setIsLoading(false);
      setUserData(user_data);
    }
    getData();
  }, [getTokenSilently]);

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Card>{JSON.stringify(userData, null, 2)}</Card>
    </Container>
  );
};

export default UserInfo;
