import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import MUIDataTable from "mui-datatables";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { API } from "aws-amplify";

import { useAuth0 } from "../react-auth0-spa";
import { StateContext } from "./Context";

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function QueryCalcs() {
  const classes = useStyles();
  const state = useContext(StateContext);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getTokenSilently, loading, claims } = useAuth0();
  useEffect(() => {
    async function getData(league) {
      const token = await getTokenSilently();
      let apiName = "youcapper";
      let path = "/calculations";
      let myInit = {
        queryStringParameters: {
          league: league,
          admin: false
        },
        headers: {
          authorization: `Bearer ${token}`
        }
      };
      if (
        !loading &&
        typeof claims !== "undefined" &&
        claims["https://youcapperpicks.com/permissions"] &&
        claims["https://youcapperpicks.com/permissions"].includes("app:admin")
      ) {
        myInit.queryStringParameters.admin = true;
      }
      setIsLoading(true);
      try {
        let calculations = await API.get(apiName, path, myInit);
        setIsLoading(false);
        setData(calculations);
      } catch (err) {
        console.log(err);
      }
    }
    getData(state.league);
  }, [state.league, getTokenSilently, claims, loading]);

  const perc_nf = new Intl.NumberFormat("en-US", {
    style: "percent",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const columns = [
    {
      name: "id",
      label: "Query ID",
      options: { filter: false, sort: true }
    },
    {
      name: "type",
      label: "Pick Type",
      options: { filter: false, sort: false, display: "false" }
    },
    {
      name: "win_percent",
      label: "Historic Win %",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return perc_nf.format(value);
        }
      }
    },
    {
      name: "win_percent_last_ten",
      label: "L10 Win %",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return perc_nf.format(value);
        }
      }
    },
    {
      name: "win_count",
      label: "Historic Wins",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "loss_count",
      label: "Historic Losses",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "push_count",
      label: "Historic Pushes",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "win_count_last_ten",
      label: "Wins (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "loss_count_last_ten",
      label: "Losses (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "push_count_last_ten",
      label: "Pushes (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "win_streak",
      label: "Win Streak",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "loss_streak",
      label: "Loss Streak",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "pickcount",
      label: "Available Picks",
      options: {
        filter: true,
        sort: true,
        display: "false",
        filterOptions: {
          names: ["At Least 1", "At Least 3"],
          logic(pickcount, filterVal) {
            const show =
              (filterVal.indexOf("At Least 1") >= 0 && pickcount >= 1) ||
              (filterVal.indexOf("At Least 3") >= 0 && pickcount >= 3);
            return !show;
          }
        },
        filterList: []
      }
    }
  ];

  const ou_columns = [
    {
      name: "id",
      label: "Query ID",
      options: { filter: false, sort: true }
    },
    {
      name: "type",
      label: "Pick Type",
      options: { filter: false, sort: false, display: "false" }
    },
    {
      name: "win_percent",
      label: "Historic Over %",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return perc_nf.format(value);
        }
      }
    },
    {
      name: "win_percent_last_ten",
      label: "L10 Over %",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return perc_nf.format(value);
        }
      }
    },
    {
      name: "over_count",
      label: "Historic Overs",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "under_count",
      label: "Historic Unders",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "push_count",
      label: "Historic Pushes",
      options: { filter: false, sort: true, display: "false", expand: true }
    },
    {
      name: "over_count_last_ten",
      label: "Overs (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "under_count_last_ten",
      label: "Unders (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "push_count_last_ten",
      label: "Pushes (Last 10)",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "over_streak",
      label: "Over Streak",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "under_streak",
      label: "Under Streak",
      options: { filter: true, sort: true, display: "false", expand: true }
    },
    {
      name: "pickcount",
      label: "Available Picks",
      options: {
        filter: true,
        sort: true,
        display: "false",
        filterOptions: {
          names: ["At Least 1", "At Least 3"],
          logic(pickcount, filterVal) {
            const show =
              (filterVal.indexOf("At Least 1") >= 0 && pickcount >= 1) ||
              (filterVal.indexOf("At Least 3") >= 0 && pickcount >= 3);
            return !show;
          }
        },
        filterList: []
      }
    }
  ];
  const options = {
    search: false,
    print: false,
    download: false,
    selectableRows: "none",
    responsive: "scrollMaxHeight",
    expandableRows: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 4 &&
        expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    rowsExpanded: [],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Card>
              <CardContent>
                {rowData.map(
                  (curRow, ind) =>
                    columns[ind].options.expand && (
                      <Typography key={"row-expand" + ind}>
                        {columns[ind].label}: {curRow}
                      </Typography>
                    )
                )}
              </CardContent>
            </Card>
          </TableCell>
        </TableRow>
      );
    },
    onRowsExpand: (curExpanded, allExpanded) =>
      console.log(curExpanded, allExpanded)
  };

  return (
    <Fragment>
      {data.filter(stats => stats.type === "spread").length > 0 && (
        <MUIDataTable
          title={`${state.league} Spread Statistics`}
          data={data.filter(stats => stats.type === "spread")}
          columns={columns}
          options={options}
          className={classes.table}
        />
      )}
      {data.filter(stats => stats.type === "over_under").length > 0 && (
        <MUIDataTable
          title={`${state.league} Over / Under Statistics`}
          data={data.filter(stats => stats.type === "over_under")}
          columns={ou_columns}
          options={options}
          className={classes.table}
        />
      )}
      {data.filter(stats => stats.type === "straight_up").length > 0 && (
        <MUIDataTable
          title={`${state.league} Money Line Statistics`}
          data={data.filter(stats => stats.type === "straight_up")}
          columns={columns}
          options={options}
          className={classes.table}
        />
      )}
    </Fragment>
  );
}
