import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useContentfulData} from '../../utils/useContentful';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import BlogPost from './BlogPost';

export default function BaseBlog() {
  const [{data, isLoading}] = useContentfulData(
    'emehpnr4cq1t',
    `${process.env.REACT_APP_CONTENTFUL_API_KEY}`,
    [],
  );

  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }
  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={6} sm={8}>
              {data.map((current, i) => (
                <BlogPost
                  key={`post-${i}`}
                  created={current.sys.createdAt}
                  {...current.fields}
                />
              ))}
            </Grid>
            <Grid item xs={6} sm={4}>
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="you_capper"
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  );
}
