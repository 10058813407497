import React from 'react';
import ReactMarkdown from 'react-markdown';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Moment from 'react-moment';

import {renderItem as RichText} from './RichTextEntry';

const useStyles = makeStyles({
  root: {
    maxWidth: 2500,
    marginBottom: 10,
  },
  media: {
    width: '100%',
  },
  card: {},
});

export default function BlogPost(props) {
  const hasMainContent = !!props.mainContent;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        title={props.title}
        subheader={
          <Moment format="MMMM Do YYYY, h:mm:ss a">{props.created}</Moment>
        }
      />
      {hasMainContent ? (
        <CardContent>
          {props.mainContent.content.map(item => RichText(item, classes))}
        </CardContent>
      ) : (
        <ReactMarkdown source={props.content} />
      )}
    </Card>
  );
}
