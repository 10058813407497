import React, {Fragment, useContext, useEffect} from 'react';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import useDataApi from 'use-data-api';

import {makeStyles} from '@material-ui/core/styles';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import {useQueryParam, StringParam} from 'use-query-params';
import {StateContext, DispatchContext} from './Context';
import {SET_LEAGUE} from '../actions';

const useStyles = makeStyles({
  nav: {
    top: 'auto',
    bottom: 0,
    width: '100%',
  },
});

function Leagues(props) {
  let history = useHistory();
  let location = useLocation();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [{data, isLoading, isError}] = useDataApi(
    `${process.env.REACT_APP_PICKS_API_BASE}/leagues`,
    [],
  );
  const classes = useStyles();
  const [currentLeague, setCurrentLeague] = useQueryParam(
    'league',
    StringParam,
  );

  useEffect(() => {
    if (!currentLeague) {
      if (state && state.league) {
        setCurrentLeague(state.league);
      } else {
        setCurrentLeague('NBA');
      }
    }
  }, [state, currentLeague, setCurrentLeague]);

  return (
    <Fragment>
      {isError && <div>Something went wrong ...</div>}
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <AppBar position="sticky" className={classes.nav}>
          <BottomNavigation
            value={currentLeague}
            onChange={(event, newValue) => {
              dispatch({type: SET_LEAGUE, payload: newValue});
              setCurrentLeague(newValue);
              history.push(`${location.pathname}?league=${newValue}`);
            }}
            showLabels>
            {data
              .filter(league => league.name !== 'MLB')
              .map(item => (
                <BottomNavigationAction
                  key={item.name}
                  label={item.name}
                  value={item.name}
                  icon={<RestoreIcon />}
                />
              ))}
          </BottomNavigation>
        </AppBar>
      )}
    </Fragment>
  );
}

export default Leagues;
