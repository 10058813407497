import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import QueryCalcs from "./QueryCalcs";
import Leagues from "./Leagues";

export default function WrappedStats() {
  return (
    <>
      <Container fixed>
        <Grid item xs={12}>
          <QueryCalcs />
        </Grid>
      </Container>
      <Leagues />
    </>
  );
}
