import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Picks from "./Picks";
import Leagues from "./Leagues";

export default function WrappedPicks() {
  return (
    <>
      <Container fixed>
        <Grid item xs={12}>
          <Picks />
        </Grid>
      </Container>
      <Leagues />
    </>
  );
}
