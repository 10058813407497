import {useEffect, useReducer} from 'react';
import {createClient} from 'contentful';

const createContentfulClient = (spaceId, accessToken) => {
  return createClient({
    space: spaceId,
    accessToken: accessToken,
  });
};

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {...state, isLoading: true, isError: false};
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const useContentfulData = (spaceId, accessToken, initialData) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    client: createContentfulClient(spaceId, accessToken),
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({type: 'FETCH_INIT'});
      try {
        state.client.getEntries().then(function(entries) {
          dispatch({type: 'FETCH_SUCCESS', payload: entries.items});
        });
      } catch (error) {
        console.log(error);
        dispatch({type: 'FETCH_FAILURE'});
      }
    };
    fetchData();
    return () => {};
  }, [state.client]);
  return [state];
};

export {createContentfulClient, useContentfulData};
