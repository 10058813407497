import React from 'react';
import {Link} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function NavMenuItem(props) {
  return (
    <ListItem
      button
      key={`${props.menuKey}-${props.name}`}
      component={Link}
      to={props.route}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
}
