import React, {Fragment, useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import {API} from 'aws-amplify';
import MUIDataTable from 'mui-datatables';
import {useAuth0} from '../../react-auth0-spa';
import {StateContext} from '../Context';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    margin: theme.spacing(1),
  },
  textField: {
    width: theme.spacing(1) * 20,
  },
  wideTextField: {
    width: theme.spacing(1) * 50,
  },
  table: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function Queries() {
  const classes = useStyles();
  const state = useContext(StateContext);
  const [nextQueryId, setNextQueryId] = useState(-1);
  const {getTokenSilently, loading, claims} = useAuth0();
  const [queryData, setQueryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [hasQueryPublish, setHasQueryPublish] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isError, setIsError] = useState(false);
  const addQuery = () => {
    setIsEditable(true);
    setQueryData([
      ...queryData,
      {
        id: nextQueryId,
        query: '',
        description: '',
        query_type: 'spread',
        active: false,
        disabled: false,
        tainted: true,
      },
    ]);
    setNextQueryId(nextQueryId - 1);
  };

  const setQuery = (queryId, fieldName, data) => {
    let tmpQueryData = [...queryData];
    let tmpObjIndex = tmpQueryData.findIndex(x => x.id === queryId);
    tmpQueryData[tmpObjIndex][fieldName] = data;
    //todo: only set tainted if there were actually changes
    tmpQueryData[tmpObjIndex]['tainted'] = true;
    setQueryData(tmpQueryData);
  };

  const handleRunQuery = value => {
    console.log(`Run: ${value}`);
    async function runQuery() {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = `/queries/${value}/run`;
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      try {
        await API.get(apiName, path, myInit);
      } catch (err) {
        // TODO: Send error to Sentry
        console.log(err);
      }
    }
    runQuery();
  };

  const handleArchiveQuery = value => {
    console.log(`Archive: ${value}`);
    async function archiveQuery() {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = `/queries/${value}`;
      let myInit = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      try {
        let tmpQueryData = [...queryData];
        let tmpObjIndex = tmpQueryData.findIndex(x => x.id === value);
        await API.del(apiName, path, myInit);
        tmpQueryData.splice(tmpObjIndex, 1);
        setQueryData(tmpQueryData);
      } catch (err) {
        // TODO: Send error to Sentry
        console.log(err);
      }
    }
    archiveQuery();
  };

  const saveQueries = () => {
    async function saveQueryData(
      httpMethod,
      queryId,
      query,
      queryType,
      queryDescription,
      queryActive,
      queryStatus,
    ) {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let data = {};

      let myInit = {
        body: {
          query: query,
          query_type: queryType,
          description: queryDescription,
          active: queryActive,
          status: queryStatus || 'private',
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      if (query === '' || queryDescription === '') {
        return;
      }
      if (httpMethod === 'PUT') {
        data = await API.put(apiName, `/queries/${queryId}`, myInit);
      } else if (httpMethod === 'POST') {
        myInit.body.league = state.league;
        data = await API.post(apiName, '/queries', myInit);
      }
      let tmpQueryData = [...queryData];
      let tmpObjIndex = tmpQueryData.findIndex(x => x.id === queryId);
      tmpQueryData[tmpObjIndex] = data;
      setQueryData(tmpQueryData);
    }
    queryData.forEach(qd => {
      if (qd.tainted) {
        if (qd.id < 0) {
          saveQueryData(
            'POST',
            qd.id,
            qd.query,
            qd.query_type,
            qd.description,
            qd.active,
            qd.status,
          );
        } else {
          saveQueryData(
            'PUT',
            qd.id,
            qd.query,
            qd.query_type,
            qd.description,
            qd.active,
            qd.status,
          );
        }
      }
    });
  };

  useEffect(() => {
    async function getData(league) {
      const token = await getTokenSilently();
      let apiName = 'youcapper';
      let path = '/queries';
      let myInit = {
        queryStringParameters: {
          league: league,
          admin: false,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      if (
        !loading &&
        typeof claims !== 'undefined' &&
        claims['https://youcapperpicks.com/permissions'] &&
        claims['https://youcapperpicks.com/permissions'].includes('app:admin')
      ) {
        myInit.queryStringParameters.admin = true;
        setHasQueryPublish(true);
        setIsAdmin(true);
      }
      if (
        !loading &&
        typeof claims !== 'undefined' &&
        claims['https://youcapperpicks.com/permissions'] &&
        claims['https://youcapperpicks.com/permissions'].includes(
          'publish:queries',
        )
      ) {
        setHasQueryPublish(true);
      }
      setIsLoading(true);
      try {
        let queries = await API.get(apiName, path, myInit);
        setIsLoading(false);
        setQueryData(queries);
      } catch (err) {
        console.log(err);
      }
    }
    getData(state.league);
  }, [state.league, getTokenSilently, claims, loading]);

  const columns = [
    {
      name: 'id',
      label: 'Query ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'query',
      label: 'Query',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            value={value}
            control={
              <TextField
                className={classes.wideTextField}
                disabled={!isEditable}
                value={value}
                multiline
                rowsMax="4"
                placeholder="Query"
              />
            }
            onChange={event => updateValue(event.target.value)}
            onBlur={event =>
              setQuery(tableMeta.rowData[0], 'query', event.target.value)
            }
          />
        ),
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControlLabel
            value={value}
            control={
              <TextField
                value={value}
                className={classes.textField}
                disabled={!isEditable}
                placeholder="Description"
              />
            }
            onChange={event => updateValue(event.target.value)}
            onBlur={event =>
              setQuery(tableMeta.rowData[0], 'description', event.target.value)
            }
          />
        ),
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              value={value ? 'Yes' : 'No'}
              control={
                <Switch
                  color="primary"
                  checked={value}
                  disabled={!isEditable}
                  value={value ? 'Yes' : 'No'}
                  onClick={() =>
                    setQuery(tableMeta.rowData[0], 'active', value)
                  }
                />
              }
              onChange={event => {
                updateValue(event.target.value === 'Yes' ? false : true) ||
                  setQuery(
                    tableMeta.rowData[0],
                    'active',
                    event.target.value === 'Yes' ? false : true,
                  );
              }}
            />
          );
        },
      },
    },
    {
      name: 'query_type',
      label: 'Query Type',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              value={value}
              control={
                <Select value={value} disabled={!isEditable}>
                  <MenuItem value="spread">Spread</MenuItem>
                  <MenuItem value="over_under">Over/Under</MenuItem>
                  <MenuItem value="moneyline">Moneyline</MenuItem>
                </Select>
              }
              onChange={event => {
                updateValue(event.target.value) ||
                  setQuery(
                    tableMeta.rowData[0],
                    'query_type',
                    event.target.value,
                  );
              }}
            />
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        display: hasQueryPublish ? 'true' : 'false',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              value={value}
              control={
                hasQueryPublish ? (
                  <Select value={value || 'private'} disabled={!isEditable}>
                    <MenuItem value="system">System</MenuItem>
                    <MenuItem value="private">Private</MenuItem>
                    <MenuItem value="premium">Premium</MenuItem>
                  </Select>
                ) : (
                  <p>{value}</p>
                )
              }
              onChange={event => {
                updateValue(event.target.value) ||
                  setQuery(tableMeta.rowData[0], 'status', event.target.value);
              }}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Run Query',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              size="small"
              color="primary"
              aria-label="run"
              onClick={() => handleRunQuery(value)}
              className={classes.fab}>
              <PlayArrowIcon />
            </Fab>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Archive Query',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              size="small"
              color="primary"
              aria-label="delete"
              disabled={!isEditable}
              onClick={() => handleArchiveQuery(value)}
              className={classes.fab}>
              <DeleteIcon />
            </Fab>
          );
        },
      },
    },
  ];
  if (isAdmin) {
    columns.push({
      name: 'owner',
      label: 'Query Owner',
      options: {
        filter: true,
      },
    });
  }
  const options = {
    search: false,
    print: false,
    download: false,
    selectableRows: 'none',
    responsive: 'scrollMaxHeight',
  };
  return (
    <Fragment>
      {isError && <div>Something went wrong ...</div>}
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <Grid item xs={12}>
          <Fab
            color="primary"
            aria-label="add"
            onClick={addQuery}
            variant="extended"
            className={classes.root}>
            <AddIcon />
            Query
          </Fab>
          <Fab
            variant="extended"
            color="primary"
            aria-label="delete"
            onClick={() => setIsEditable(!isEditable) || saveQueries()}
            className={classes.root}>
            {!isEditable ? <EditIcon /> : <SaveIcon />}
            Queries
          </Fab>
          <MUIDataTable
            title={`${state.league} Queries`}
            data={queryData}
            columns={columns}
            options={options}
            className={classes.table}
          />
        </Grid>
      )}
    </Fragment>
  );
}

export default Queries;
