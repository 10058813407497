import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CreateIcon from '@material-ui/icons/Create';
import SportsIcon from '@material-ui/icons/Sports';
import StorageIcon from '@material-ui/icons/Storage';
import {useAuth0} from '../../react-auth0-spa';

import NavGroup from './NavGroup';
import LoginGroup from './LoginGroup';
import logo from '../../static/logo2_wr.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  nav: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

export default function Menu() {
  const {isAuthenticated, loading, user, claims} = useAuth0();
  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
    queryWriteAdded: false,
    adminAdded: false,
  });

  const [menuItems, setMenuItems] = React.useState([
    {
      groupName: 'research',
      groupValues: [
        {
          name: 'Blog',
          icon: <CreateIcon />,
          route: '/blog',
        },
        {
          name: 'Picks',
          icon: <SportsIcon />,
          route: '/picks',
        },
        {
          name: 'Stats',
          icon: <AssessmentIcon />,
          route: '/stats',
        },
      ],
    },
    /*{
      groupName: 'billing',
      groupValues: [
        {
          name: 'Account',
          icon: <SportsIcon />,
          route: '/account',
        },
        {
          name: 'Billing',
          icon: <AssessmentIcon />,
          route: '/billing',
        },
        {
          name: 'Profile',
          icon: <SportsIcon />,
          route: '/profile',
        },
      ],
    },*/
  ]);
  const toggleDrawer = (side, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({...state, [side]: open});
  };

  const queryItem = {
    groupName: 'queries',
    groupValues: [
      {
        name: 'Manage',
        icon: <SportsIcon />,
        route: '/queries',
      },
    ],
  };
  const adminItem = {
    groupName: 'administration',
    groupValues: [
      {
        name: 'Database Maintenance',
        icon: <StorageIcon />,
        route: '/admin/database',
      },
      {
        name: 'Leagues',
        icon: <SportsIcon />,
        route: '/admin/leagues',
      },
      {
        name: 'Sports',
        icon: <SportsIcon />,
        route: '/admin/sports',
      },
    ],
  };
  useEffect(() => {
    if (
      !loading &&
      typeof claims !== 'undefined' &&
      claims['https://youcapperpicks.com/permissions']
    ) {
      if (
        claims['https://youcapperpicks.com/permissions'].includes('app:admin')
      ) {
        if (!state.adminAdded) {
          setMenuItems(menuItems => [...menuItems, adminItem, queryItem]);
          setState({...state, adminAdded: true});
        }
      } else if (
        claims['https://youcapperpicks.com/permissions'].includes(
          'write:queries',
        )
      ) {
        if (!state.queryWriteAdded) {
          setMenuItems(menuItems => [...menuItems, queryItem]);
          setState({...state, queryWriteAdded: true});
        }
      }
    }
  }, [menuItems, loading, claims, state, setState, adminItem, queryItem]);
  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.nav}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={toggleDrawer('left', true)}
            aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
            <div
              className={classes.list}
              role="presentation"
              onClick={toggleDrawer('left', false)}
              onKeyDown={toggleDrawer('left', false)}>
              {menuItems.map(menu => (
                <Fragment key={menu.groupName}>
                  <NavGroup
                    name={menu.groupName}
                    menuGroup={menu.groupValues}
                  />
                  <Divider />
                </Fragment>
              ))}
              <LoginGroup />
            </div>
          </Drawer>
          <Typography variant="h6" className={classes.title}>
            <Link href="/" color="inherit">
              <img src={logo} alt="logo" height="100px" />
            </Link>
          </Typography>
          {isAuthenticated && (
            <Typography>
              {user['https://youcapperpicks.com/display_name'] || user.name}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
