import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

export default function Billing() {
  return (
    <div className="billing-home">
      <Grid item xs={12}>
        <p>Billing Stuff Here</p>
      </Grid>
      <Grid item xs={4}>
        <Button data-cb-type="checkout" data-cb-plan-id="unlimited-basic">
          subscribe
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button data-cb-type="portal">Manage account</Button>
      </Grid>
    </div>
  );
}
