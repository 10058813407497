import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import { useAuth0 } from "../react-auth0-spa";
import UserInfo from "./profile/UserInfo";

const Profile = () => {
  const { loading, user, claims } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              aria-label="username"
              src={user.picture}
              alt="profile-picture"
            />
          }
          title={user.name}
          subheader={user.email}
        />
        <UserInfo />
        <code>{JSON.stringify(user, null, 2)}</code>
        <p>*********</p>
        <code>{JSON.stringify(claims, null, 2)}</code>
        <p>*********</p>
      </Card>
    </Container>
  );
};

export default Profile;
