import React from "react";
import Button from "@material-ui/core/Button";

export default function Account() {
  return (
    <div className="account-home">
      <Button data-cb-type="portal">Manage account</Button>
    </div>
  );
}
