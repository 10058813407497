import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/auth/PrivateRoute";
import DatabaseAdmin from "./components/admin/Database";
import LeagueManagement from "./components/admin/Leagues";
import SportManagement from "./components/admin/Sports";
import WrappedPicks from "./components/WrappedPicks";
import WrappedStats from "./components/WrappedStats";
import WrappedQueries from "./components/WrappedQueries";
import Account from "./components/Account";
import Billing from "./components/Billing";
import BaseBlog from "./components/blog/BaseBlog";
import Profile from "./components/Profile";
import NotFound from "./components/errors/NotFound";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={BaseBlog} />
      <Route path="/blog" exact component={BaseBlog} />
      <PrivateRoute path="/login" exact component={BaseBlog} />
      <PrivateRoute path="/picks" component={WrappedPicks} />
      <PrivateRoute path="/stats" component={WrappedStats} />
      <PrivateRoute path="/queries" component={WrappedQueries} />
      <PrivateRoute path="/admin/database" component={DatabaseAdmin} />
      <PrivateRoute path="/admin/leagues" component={LeagueManagement} />
      <PrivateRoute path="/admin/sports" component={SportManagement} />
      <PrivateRoute path="/account" component={Account} />
      <PrivateRoute path="/billing" component={Billing} />
      <PrivateRoute path="/profile" component={Profile} />
      <Route component={NotFound} />
    </Switch>
  );
}
export default Routes;
